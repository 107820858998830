/* eslint-disable react/display-name */
import React from "react";
import { View } from "react-native";
import { Text } from "react-native-elements";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import get from "lodash/get";

import tw from "tailwind-rn";

import { fonts, fontWeights } from "styles/theme";

import { FormFieldAutoComplete } from "components/elements/forms";

import { basicPaymentInfo, basicCustomerInfo } from "../config";

import { OpenAdditionalInfoArea, OpenQuickSaleArea } from "../index";

import I18NContext from "library/contexts/i18N";

import UserProfileStorage from "library/storage/userProfile";

import useStateIfMounted from "library/utils/useStateIfMounted";
import {
  setCreateOrderShopCode,
  setShopCodeForDraftOrder,
} from "library/sagas/views/home/drawer/create-order/slice";

const ShopSelection = React.memo(
  ({
    isSmallScreen,
    memberCodes,
    values,
    setSideCarOpen,
    sideCarKey,
    isWalkInEntEnabled,
    isEditOrder = false,
    isActionDraft = false,
  }) => {
    const { setFieldValue } = useFormikContext();
    const { messages, Localise } = React.useContext(I18NContext);
    const dispatch = useDispatch();
    const [selectShopQuery, setSelectShopQuery] = useStateIfMounted("");

    const shopNames = UserProfileStorage.getAllShopNames();

    const { sendingMember = "" } = values;

    const sendingOriginData = memberCodes.map((each) => {
      const shopName = shopNames[each] || "";
      return { code: each, shopName: shopName };
    });

    const isDisabled = isEditOrder || isActionDraft;

    return (
      <View
        style={[
          tw("flex flex-row w-full items-center justify-between"),
          {
            zIndex: 6,
          },
        ]}
      >
        {memberCodes.length > 1 && (
          <View
            style={[
              tw("flex flex-row"),
              {
                width: isSmallScreen ? "100%" : "70%",
                opacity: isDisabled ? 0.7 : 1,
              },
            ]}
            pointerEvents={isDisabled ? "none" : "auto"}
          >
            {isSmallScreen && (
              <Text
                style={[
                  fonts.heading5,
                  {
                    fontSize: 13,
                    fontWeight: fontWeights.normal,
                    marginRight: 8,
                    marginTop: 8,
                  },
                ]}
              >
                {Localise(messages, "Select Shop")} *
              </Text>
            )}

            <FormFieldAutoComplete
              autoCapitalize="none"
              name="sendingMemberField"
              placeholder={Localise(messages, "Select Shop")}
              data={sendingOriginData.filter((each) => {
                const { code = "", shopName = "" } = each;
                return (
                  code.toLowerCase().includes(selectShopQuery.toLowerCase()) ||
                  shopName.toLowerCase().includes(selectShopQuery.toLowerCase())
                );
              })}
              onChangeText={(text) => {
                setSelectShopQuery(text);
              }}
              outerContainerStyle={{
                flex: 1,
                zIndex: 1,
                paddingLeft: 0,
              }}
              showOnFocus={true}
              clearTextOnBackTab={true}
              isMultiSelect={false}
              listDisplayValues={["code", "shopName"]}
              popperPlacement={"bottom"}
              onSelect={(selectedValue) => {
                if (!selectedValue) return;

                const { code, shopName } = selectedValue;

                // Resetting only when user selected different member code
                if (code !== sendingMember) {
                  setFieldValue("sendingMember", code);
                  setFieldValue("sendingMemberField", `${code} ${shopName}`);
                  dispatch(setCreateOrderShopCode(code));
                  dispatch(setShopCodeForDraftOrder(code));
                  setSelectShopQuery("");

                  // resetting customer details, applied discounts, taxes and payment details when user changed sender origin
                  setFieldValue("hasCustomerInfo", false);
                  setFieldValue("customerInfo", basicCustomerInfo);
                  delete values.actualCustomerInfo;
                  setFieldValue("savePayment", false);
                  setFieldValue("paymentDetails", basicPaymentInfo);
                  setFieldValue("orderItems.0.applyTaxOnDF", false);
                  setFieldValue("orderItems.0.applyTaxOnRDF", false);
                  setFieldValue("giftCard", "");

                  const orderItemsCopy = get(values, "orderItems");
                  if (orderItemsCopy && orderItemsCopy.length) {
                    orderItemsCopy.map((_item, index) => {
                      setFieldValue(`orderItems.${index}.taxInfo`, {
                        taxAmount: 0,
                        taxRate: 0.0,
                      });

                      let updatedDeliveryInfo = get(
                        values,
                        `orderItems.${index}.deliveryInfo`,
                        {}
                      );

                      updatedDeliveryInfo.deliveryMethod =
                        values?.isQuickSaleEnabled ? "WALK_IN" : "";
                      updatedDeliveryInfo.pickUpDateTime = "";
                      updatedDeliveryInfo.pickUpBy = "";
                      updatedDeliveryInfo.shopDayTimings = {};

                      setFieldValue(
                        `orderItems.${index}.deliveryInfo`,
                        updatedDeliveryInfo
                      );

                      setFieldValue(
                        `orderItems.${index}.deliveryMethod`,
                        values?.isQuickSaleEnabled ? "WALK_IN" : ""
                      );

                      const isRushOrder = get(
                        values,
                        `orderItems.${index}.isRushOrder`,
                        false
                      );

                      // Incase of rushOrder then we are clearing the deliveryInstructions because deliveryInstructions may have rushInstructions attached to it & rush instructions time may vary based on selected shop.
                      if (isRushOrder) {
                        setFieldValue(
                          `orderItems.${index}.deliveryInfo.deliveryInstructions`,
                          ""
                        );
                      }

                      // on sender origin change, resetting selected products as per #5 mentioned at https://ftdcorp.atlassian.net/browse/MSOL-9366?focusedCommentId=208512
                      setFieldValue(`orderItems.${index}.lineItems`, []);
                      setFieldValue(`orderItems.${index}.price`, []);
                    });
                  }
                }
              }}
              onClear={() => {
                setFieldValue("sendingMember", "");
                setFieldValue("sendingMemberField", "");
                dispatch(setShopCodeForDraftOrder(""));
              }}
              showErrorOnTouched={true}
            />
          </View>
        )}

        {!isSmallScreen && (
          <View style={[tw("flex flex-row"), { marginLeft: "auto" }]}>
            {isWalkInEntEnabled && !isEditOrder && (
              <OpenQuickSaleArea
                isQuickSaleEnabled={values?.isQuickSaleEnabled}
                setFieldValue={setFieldValue}
              />
            )}
            <OpenAdditionalInfoArea
              setSideCarOpen={setSideCarOpen}
              sideCarKey={sideCarKey}
            />
          </View>
        )}
      </View>
    );
  }
);

export default ShopSelection;
