const ORDERS = Object.freeze({
  FLORIST_DELIVERED: "FLORIST_DELIVERED",
  FLORIST_PARTNER: "FLORIST_PARTNER",
  STORE_PICKUP: "STORE_PICKUP",
  PHONE_OUT: "PHONE_OUT",
  WALK_IN: "WALK_IN",
  PARTNER_STORE_PICKUP: "PARTNER_STORE_PICKUP",
  MOL_FLORIST_DELIVERED: "MOL_FLORIST_DELIVERED",
  FOL_FLORIST_DELIVERED: "FOL_FLORIST_DELIVERED",
  MOL_CUSTOMER_PICKUP: "MOL_CUSTOMER_PICKUP",
  DELIVERY_SERVICE: "DELIVERY_SERVICE",
});

export default ORDERS;
