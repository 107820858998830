import React, { useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { View, Platform } from "react-native";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { PaymentSection } from "library/utils/payment-options";
import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";

import UserProfileStorage from "library/storage/userProfile";

import { getPrice } from "library/utils/createOrder";
import { Entitlements } from "library/utils/entitlements";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { ORDERS } from "library/constants";

const PaymentDetails = ({
  values,
  setValues,
  setFieldValue,
  subTotal,
  subTotals,
  paymentOptions,
  sendingMemberCode,
  mandatorySave = false,
  isSubscriptionPage = false,
  isEditOrder = false,
  grandTotal,
  enableSplitPayment = false,
  setValidationOnChange = () => {},
  houseAccountFormRef,
  onHouseAccountUpdateSuccess,
  onHouseAccountUpdateReject,
  onHouseAccountCreditLimitChange,
  setInnerFormLoading,
  showInvoiceOnInactive = false,
}) => {
  const { messages, Localise } = useContext(I18NContext);
  const { permissions = {} } = useContext(AppSettingsContext);

  //const sessionId = useSelector(selectSessionId);
  const orderDetailsResponse = useSelector(selectApiResponse);
  const paymentDetailsRef = useRef([]);

  let customerSpecificPaymentOptions = [];
  const path = `paymentDetails.paymentMethod.0`;
  const data = get(values, path, {});
  const {
    customerId = "",
    firstPaymentInfo = {},
    houseAccountInfo: { isActive = false } = {},
  } = get(values, "customerInfo", {});
  const { orderItems = [], paymentDetails = {} } = values;

  //Finding $0 order from multi orders whle creating an order
  let eachOrderTotal = [];
  orderItems?.forEach((eachOrder, index) => {
    const { price = [] } = eachOrder;
    let orderTotal = 0;

    price?.forEach((eachOrderPrice, index) => {
      orderTotal += parseFloat(eachOrderPrice?.value || 0);
    });
    eachOrderTotal[index] = orderTotal;
  });
  const isOrderTotalZero = eachOrderTotal?.filter((value) => value === 0);

  let totalTax = 0;
  let totalDeliveryFee = 0;
  let totalRelayFee = 0;
  let totalRetailDeliveryFee = 0;

  const orderSubTotal = isSubscriptionPage
    ? subTotals.reduce((a, b) => a + b)
    : subTotal;

  // we can clear this calculation & we can use getTotalPrice but because of subscription related subTotals leaving it like this...
  orderItems.forEach((each) => {
    totalDeliveryFee += parseFloat(each?.deliveryFee || 0);
    totalTax += each?.taxInfo?.taxAmount || 0;
    totalRelayFee += parseFloat(each?.relayFee || 0);
    totalRetailDeliveryFee += parseFloat(each?.retailDeliveryFee || 0);
  });

  let orderTotal =
    parseFloat(getPrice(totalTax)) +
    parseFloat(getPrice(orderSubTotal)) +
    parseFloat(getPrice(totalDeliveryFee)) +
    parseFloat(getPrice(totalRelayFee)) +
    parseFloat(getPrice(totalRetailDeliveryFee));

  const hasSettlementError = get(
    orderDetailsResponse,
    "orderItems.0.hasSettlementError",
    false
  );

  const isInvoiceAsPaymentMethod =
    get(orderDetailsResponse, "paymentDetails.paymentMethod", []).filter(
      (item) => (item?.paymentMethodType ?? "") === "INVOICE" && item
    ).length > 0;

  if (isEditOrder) {
    const amountDue = get(values, "paymentDetails.amountDue", 0);
    orderTotal = amountDue;
  }

  let isPickupOrderNotPresent = [];
  orderItems.forEach((each) => {
    each?.deliveryInfo?.deliveryMethod !== "STORE_PICKUP" &&
      isPickupOrderNotPresent.push(each);
  });

  const selectedShopPermissions = get(permissions, sendingMemberCode, {});

  const isCPSEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.CUSTOMER_SEARCH);

  const {
    house_account_invoice = "",
    credit_card = "",
    pay_later = "",
  } = UserProfileStorage.getShopPreferences(sendingMemberCode);

  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const deliveryMethod =
    orderItems?.length === 1 ? orderItems[0]?.deliveryMethod : "";

  const isPaymentEntitlementEnabled = selectedShopPermissions[
    Entitlements.CREATE_ORDER
  ]?.includes(Entitlements.CREATE_ORDER_PAGE.PAYMENT_SECTION);
  const isPaymentPending =
    paymentDetails?.paymentStatus?.toLowerCase() === "pending";

  //if (!isElectronApp || sessionId) {
  if (
    isCPSEntitlementEnabled &&
    credit_card === "true" &&
    !isEmpty(firstPaymentInfo) &&
    (!isEditOrder ||
      (isEditOrder && (!isInvoiceAsPaymentMethod || hasSettlementError)))
  ) {
    customerSpecificPaymentOptions.push({
      label: Localise(messages, "Saved Payment"),
      value: "SAVED_CARD",
    });
  }

  if (
    house_account_invoice === "true" &&
    (showInvoiceOnInactive ? customerId : isActive) &&
    (!isEditOrder ||
      (isEditOrder && (isInvoiceAsPaymentMethod || hasSettlementError)) ||
      isPaymentPending)
  ) {
    customerSpecificPaymentOptions.push({
      label: Localise(messages, "Invoice"),
      value: "INVOICE",
    });
  }

  useEffect(() => {
    customerId &&
      setFieldValue(
        "customerInfo.houseAccountInfo.orderTotal",
        data.paymentMethodType === "INVOICE" ? orderTotal : 0
      );
  }, [data.paymentMethodType, orderTotal, customerId]);

  useEffect(() => {
    const updatedValues = cloneDeep(values);
    setValues({
      ...updatedValues,
      "paymentDetails.paymentMethod.0.savePayment":
        data.savePayment || mandatorySave,
    });
  }, [data.savePayment, mandatorySave]);

  /**
   * As we are not resetting sender origin in create order screen, Payment Terminal option is not auto selecting
   * because of sendingMember useEffect is not triggering in parent component. So keeping below code here also.
   * */
  useEffect(() => {
    if (isElectronApp) {
      let PaymentTerminalIndex = paymentOptions.findIndex(
        (paymentOption) => paymentOption.value === "PAYMENT_TERMINAL"
      );

      if (PaymentTerminalIndex >= 0) {
        setFieldValue(
          "paymentDetails.paymentMethod.0.paymentMethodType",
          "PAYMENT_TERMINAL"
        );
      }
    }
  }, []);

  useEffect(() => {
    if (deliveryMethod !== "STORE_PICKUP") {
      paymentDetails?.paymentMethod.map((payment, index) => {
        const paymentMethodType = payment.paymentMethodType;
        if (paymentMethodType === "PAY_LATER") {
          if (index > 0) paymentDetailsRef.current.remove(`payment-${index}`);

          // modified this in order to prefill and auto select Payment Terminal option in desktop app
          setFieldValue(
            `paymentDetails.paymentMethod.${index}.paymentMethodType`,
            ""
          );
        }
      });
    }
  }, [deliveryMethod]);

  const isPayLaterVisible =
    isOrderTotalZero?.length === 0 &&
    isPaymentEntitlementEnabled &&
    pay_later === "true"
      ? isEditOrder
        ? isPaymentPending
        : orderItems.every(
            (each) => each?.deliveryInfo?.deliveryMethod === ORDERS.STORE_PICKUP
          )
      : false;

  const payLaterPaymentOption = isPayLaterVisible
    ? [
        {
          label: Localise(messages, "Pay Later"),
          value: "PAY_LATER",
        },
      ]
    : [];

  const updatedPaymentOptions = paymentOptions
    .concat(customerSpecificPaymentOptions)
    .concat(payLaterPaymentOption);

  const labelStyle = useRef({
    fontWeight: "normal",
  });

  return (
    <View style={{ marginLeft: 5, marginTop: 5 }}>
      <PaymentSection
        paymentOptions={updatedPaymentOptions}
        {...{
          paymentDetailsRef,
          grandTotal,
          mandatorySave,
          isOrderTotalZero,
          enableSplitPayment,
          house_account_invoice,
          isEditOrder,
        }}
        enableCPS={isCPSEntitlementEnabled}
        labelStyle={labelStyle.current}
        isPayLaterEnabled={isPickupOrderNotPresent?.length === 0}
        setValidationOnChange={setValidationOnChange}
        houseAccountFormRef={houseAccountFormRef}
        onHouseAccountUpdateSuccess={onHouseAccountUpdateSuccess}
        onHouseAccountUpdateReject={onHouseAccountUpdateReject}
        onHouseAccountCreditLimitChange={onHouseAccountCreditLimitChange}
        setInnerFormLoading={setInnerFormLoading}
        showInvoiceOnInactive={showInvoiceOnInactive}
      />
    </View>
  );
};

export default PaymentDetails;
