/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { Platform, SafeAreaView, AppState } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import Cookies from "js-cookie";
import UserAuthContext from "library/contexts/userAuth";
import Login from "components/views/login";
import Authorize from "components/views/login/authorize";
import Home from "components/views/home";
import User from "components/views/user";
import TermsAndConditions from "components/views/terms";
import PrivacyPolicy from "components/views/privacy";
import HelpPage from "components/views/help";
import FeedbackPage from "components/views/feedback";
import OrderDeliveryStatusPage from "components/views/order-delivery-status";
import EventApprovalConfirmation from "components/views/event-approval-confirmation";
import DemoPage from "components/views/demo";
import OnDemandEmailContent from "components/views/onDemand-email-content";
import { colors, backgroundColors, theme } from "styles/theme";
import { getRouteDetails } from "library/utils/url";
import Environment from "library/utils/environment";
import useStateIfMounted from "library/utils/useStateIfMounted";
import AppUpdateAlert from "../../views/app-update";
import DeviceInfo from "react-native-device-info";
import UpdatePassword from "components/views/user/reset-password";
import EnvironmentStorage from "library/storage/environment";
import messaging from "@react-native-firebase/messaging";
import I18NContext from "library/contexts/i18N";
import { Button } from "react-native-elements";
import ForeseeModal from "components/elements/foresee-form";
import { navigationRef, isReadyRef } from "library/utils/navigation";
import UserAuthUtils from "library/utils/userAuth";
import UserProfileContext from "library/contexts/userProfile";

let fcmService;

if (Platform.OS !== "web") {
  fcmService = require("library/utils/PushNotifications/FCMService").fcmService;
}

const NavTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: colors.primary,
    text: colors.primary,
    border: colors.light,
    background: backgroundColors.secondary,
  },
};

const linking = {
  config: {
    screens: {
      home: {
        path: "/",
        screens: {
          dashboard: "/dashboard",
          "create-order": "/create-order",
          orders: "/orders",
          "staff-management": "/staff-management",
          "shop-settings": {
            path: "/shop-settings",
            screens: {
              "mercury-network": "/mercury-network",
              "shop-details": "/shop-details",
              "order-settings": "/order-settings",
              "delivery-pickup": "/delivery-pickup",
              payments: "/payments",
              "catalog-settings": "/catalog-settings",
              addon: "/addon",
            },
          },
          authorize: "/authorize",
          reports: "/reports",
          "mercury-online": "/mercury-online",
          "delivery-tasks": "/delivery-tasks",
          "create-delivery-service-request": "create-delivery-service-request",
          "lmds-delivery": "/delivery",
          "lmds-routes": "/routes",
          "create-route": "/create-route",
          "edit-route": "/edit-route",
          "delivery-settings": "/delivery-settings",
          profile: "/user-profile",
          help: "/help",
          feedback: "/feedback",
          demo: "/demo",
          releases: "/releases",
          "product-catalog": {
            path: "/product-catalog",
            screens: {
              dashboard: "/dashboard/:member_code?/:handle?",
              catalog: "/catalog/:handle/:member_code?",
              product: "/product/:handle/:member_code?",
              collection: "/collection/:handle/:member_code?",
              listCollections: "/collections/:member_code?",
            },
          },
          "ftd-member-settings": {
            path: "/ftd-member-settings",
            screens: {
              "manage-groups": "/manage-groups",
              "bulk-accounts": "/bulk-accounts",
              "invalidate-sessions": "/invalidate-sessions",
              "shared-catalogs": "/shared-catalogs",
            },
          },
        },
      },
      user: {
        path: "/user",
        screens: {
          "reset-password": "/reset-password",
          "set-password": "/set-password",
          "forgot-password": "/forgot-password",
          "unlock-password": "/unlock-password",
        },
      },
    },
  },
};

const Stack = createStackNavigator();

export default function AppNavigator() {
  const { messages, Localise } = React.useContext(I18NContext);
  const {
    userProfile: { type: userType },
  } = React.useContext(UserProfileContext);

  const deviceType = UserAuthUtils.getDeviceType();
  const isFTDUser = userType === "FTD_USER";

  const isWeb = Platform.OS === "web";
  const { pathArray = [], params } = isWeb && getRouteDetails();

  const [isAppUpdateAvailable, setAppUpdateAvailable] =
    useStateIfMounted(false);

  if (!isWeb) {
    const appState = useRef(AppState.currentState);

    const _handleAppStateChange = (newAppState) => {
      // When app is resumed from background/history, then this if block is executing as we are receiving newAppState as active
      if (
        appState.current.match(/inactive|background/) &&
        newAppState === "active"
      ) {
        /**
         * Case-1 : When user minimized app by click on device home button, when user resumed app then we are comparing device build numbers again and
         * showing app update alert dialog if force app update available in order prevent user to use app.
         *
         * Case-2 : Assume we have force app update available, we shown force app update alert dialog on app launch and user clicked on Update Now then user
         * navigated to app store. Now if user navigated back to our app from iOS app store by click on default iOS back arrow then we need to show
         * app update alert again if user not updated app earlier by comparing device current & available force app update build number again.
         * */
        if (Environment.get("update_type") === "force") {
          if (
            DeviceInfo.getBuildNumber() <
            Environment.get("force_update_build_number")
          ) {
            setAppUpdateAvailable(true);
            // Clearing all push notifications, when app is resumed from background/history by click on app icon from second time onwards
            fcmService.clearAllNotifications();
          } else {
            setAppUpdateAvailable(false);
          }
        }
      } else {
        setAppUpdateAvailable(false);
      }

      appState.current = newAppState;
    };

    /**
     *  This useEffect() is called for the first time on app launch and later when user navigated to play store by click on Update Now and
     *  cameback to our app by clikc on back button then we are showing app update alert (if it's force app update) again based on app state.
     * */
    useEffect(() => {
      /**
       * We added this set time out in order to resolve issue with iOS native bug that needs to be fixed in the Modal component on iOS.
       * (Ref Link - https://github.com/facebook/react-native/issues/10471#issuecomment-635553358)
       *
       * If we not added this set time out then app update alert dilog is displaying and background UI (either login screen or dashboard etc.) is not loading.
       *
       * In Android app, with out set timeout app update alert dilog is displaying properly along with background UI.
       *
       * For consistency in both iOS & Android, I am adding that set time out with 1000 milli sec.
       *
       *  */
      setTimeout(() => {
        setAppUpdateAvailable(
          JSON.parse(Environment.get("update_needed", false))
        );
      }, 1000);

      AppState.addEventListener("change", _handleAppStateChange);

      return () => {
        AppState.removeEventListener("change", _handleAppStateChange);
      };
    }, []);

    /* For supporting the push notifications */

    useEffect(() => {
      const onRegister = ({ status, fcmToken }) => {
        // we are storing FCM Device Token in Environment Storage and we will use it later to send it to API.
        if (status === messaging.AuthorizationStatus.AUTHORIZED)
          EnvironmentStorage.setFCMDeviceToken(fcmToken);
      };

      /* 1. Use the below function if we present the push notification when the app is in foreground state
          2. Replace the empty function with onNotification in fcmService */
      const onNotification = (notification) => {
        fcmService.showLocalNotification(
          Math.floor(Math.random() * 10000 + 1),
          notification.title,
          notification.body,
          notification,
          {
            soundName: "default",
            playSound: true,
          }
        );
      };

      fcmService.register(onRegister, onNotification);
    }, []);
  }

  const enableFeedbackButton = Environment.get("ENABLE_FORESEE", false);
  const [showForeseeform, setShowForeseeform] = useState(false);
  useEffect(() => {
    return () => {
      isReadyRef.current = false;
    };
  }, []);

  const accessToken = Platform.OS === "web" ? Cookies.get("accessToken") : "";
  const refreshToken = Platform.OS === "web" ? Cookies.get("refreshToken") : "";

  const isAuthTokenAvailableInCookie = refreshToken && accessToken;

  return (
    <>
      {isAppUpdateAvailable && <AppUpdateAlert />}
      <SafeAreaView style={{ backgroundColor: "#114258", flex: 1 }}>
        <UserAuthContext.Consumer>
          {({ authToken, keepMeSignedIn }) => {
            return (
              <NavigationContainer
                theme={NavTheme}
                linking={linking}
                ref={navigationRef}
                onReady={() => {
                  isReadyRef.current = true;
                }}
              >
                <Stack.Navigator headerMode="none">
                  {pathArray[1] === "terms" ? (
                    <Stack.Screen
                      name="terms"
                      options={{
                        title: Localise(messages, "Terms & Conditions"),
                      }}
                      component={TermsAndConditions}
                    />
                  ) : pathArray[1] === "user" ? (
                    <Stack.Screen name="user">
                      {(props) => (
                        <User
                          {...props}
                          nonce={params.nonce}
                          expiry={params.expiry}
                        />
                      )}
                    </Stack.Screen>
                  ) : pathArray[1] === "privacy" ? (
                    <Stack.Screen
                      name="privacy"
                      options={{ title: Localise(messages, "Privacy Policy") }}
                      component={PrivacyPolicy}
                    />
                  ) : pathArray[1] === "order-delivery-status" ? (
                    <Stack.Screen
                      name="order-delivery-status"
                      options={{
                        title: Localise(
                          messages,
                          "Mercury HQ - Order Tracking"
                        ),
                      }}
                      component={OrderDeliveryStatusPage}
                    />
                  ) : pathArray[1] === "download-invoice" ? (
                    <Stack.Screen
                      name="download-invoice"
                      options={{
                        title: Localise(messages, "On-Demand Invoice"),
                      }}
                      component={OnDemandEmailContent}
                    />
                  ) : pathArray[1] === "event-approval-confirmation" ? (
                    <Stack.Screen
                      name="event-approval-confirmation"
                      options={{
                        title: Localise(
                          messages,
                          "Mercury HQ - Event Approval Confirmation"
                        ),
                      }}
                    >
                      {(props) => <EventApprovalConfirmation />}
                    </Stack.Screen>
                  ) : pathArray[1] === "help" && !authToken ? (
                    <>
                      <Stack.Screen
                        name="help"
                        options={{
                          title: Localise(messages, "Mercury HQ - Help"),
                        }}
                        component={HelpPage}
                      />
                      <Stack.Screen
                        name="demo"
                        options={{ title: "Mercury HQ - Demo" }}
                        component={DemoPage}
                      />
                      <Stack.Screen
                        name="feedback"
                        options={{
                          title: Localise(messages, "Mercury HQ - Feedback"),
                        }}
                        component={FeedbackPage}
                      />
                    </>
                  ) : pathArray[1] === "feedback" && !authToken ? (
                    <Stack.Screen
                      name="feedback"
                      options={{
                        title: Localise(messages, "Mercury HQ - Feedback"),
                      }}
                      component={FeedbackPage}
                    />
                  ) : authToken &&
                    (deviceType !== "web" ||
                      (deviceType === "web" &&
                        (isFTDUser || keepMeSignedIn))) ? (
                    <Stack.Screen
                      name="home"
                      options={{ title: Localise(messages, "Mercury HQ") }}
                    >
                      {(props) => <Home {...props} proxyUser={params} />}
                    </Stack.Screen>
                  ) : (
                    <>
                      <Stack.Screen
                        name="login"
                        component={
                          isAuthTokenAvailableInCookie ? Authorize : Login
                        }
                        options={{
                          title: Localise(messages, "Mercury HQ - Login"),
                        }}
                      />
                      <Stack.Screen
                        name="update-password"
                        options={{ title: "Mercury HQ - Update Password" }}
                      >
                        {(props) => (
                          <UpdatePassword {...props} isUpdatePassword />
                        )}
                      </Stack.Screen>
                    </>
                  )}
                </Stack.Navigator>
              </NavigationContainer>
            );
          }}
        </UserAuthContext.Consumer>

        {enableFeedbackButton && !isWeb && (
          <>
            <ForeseeModal
              showModal={showForeseeform}
              onClose={() => {
                setShowForeseeform(false);
              }}
            />
            <Button
              testID={"feedback-id"}
              accessibilityLabel={"feedback-id"}
              titleStyle={theme.Button.secondaryTitleStyle}
              buttonStyle={{
                ...theme.Button.secondaryButtonStyle,
                paddingVertical: 5,
                paddingHorizontal: 10,
              }}
              containerStyle={{
                position: "absolute",
                top: "50%",
                right: -30,
                transform: [{ rotate: "270deg" }],
                margin: 0,
              }}
              onPress={() => {
                setShowForeseeform(true);
              }}
              title={Localise(messages, "Feedback")}
            />
          </>
        )}
      </SafeAreaView>
    </>
  );
}
