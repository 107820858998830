const DRAFT_ORDERS = Object.freeze({
  ALREADY_DELETED_MESSAGE: "Draft order was already deleted.",
  CREATE_FAILURE_MESSAGE: "Failed to save the draft order. Please try again!",
  CREATE_SUCCESS_MESSAGE: "Draft order has been saved successfully",
  CREATING_MESSAGE: "Creating the draft order. Just a moment.",
  DELETE_FAILURE_MESSAGE: "Failed to delete the draft order. Please try again!",
  DELETE_SUCCESS_MESSAGE: "Draft order has been deleted successfully",
  EMPTY_DESCRIPTION:
    "It looks like you don't have any draft orders at the moment. Create a new order to save it as a draft.",
  EMPTY_TEXT: "No Draft Orders Available.",
  CONFIRM_MODAL_CONTENT:
    "We cannot save payment information, such as credit card details, in a draft order. Do you wish to continue saving the draft without the payment information, or cancel to continue working on the order?",
  INFO_TEXT:
    "Click on any draft order tile to open, modify, or complete your order.",
  MODIFY_FAILURE_MESSAGE: "Failed to modify the draft order. Please try again!",
  MODIFY_SUCCESS_MESSAGE: "Draft order has been modified successfully",
  MODIFYING_MESSAGE: "Modifying the draft order. Just a moment.",
  TITLE: "Draft Orders",
});

export default DRAFT_ORDERS;
